import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/github/actions-runner/_work/ssg-widgets/ssg-widgets/apps/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { TeamBadge } from "@ssg-b2b/team-badge";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "teambadge"
    }}>{`TeamBadge`}</h1>
    <p>{`Image component that shows team logo in unified fashion but with multiple variants.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={TeamBadge} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<TeamBadge />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TeamBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <TeamBadge mdxType="TeamBadge" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      